import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu, MenuProps, theme } from 'antd';
import { useEffect, useState } from 'react';
import AuthService from "../../services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faChartLine, faCircleUser, faCodeBranch, faEnvelope, faFileLines, faFileWord, faLocationArrow, faPuzzlePiece, faRightFromBracket, faShieldHalved, faSignature, faUsers } from "@fortawesome/free-solid-svg-icons";

const EmployeeLayout = ()=>{

    const navigate = useNavigate();
    const _authService = AuthService();
    const { Header, Sider, Content } = Layout;
    const [collapsed, setCollapsed] = useState(false);
    const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();

    const [ userData, setUserData ] = useState(Object);

    const [selectedKey, setSelectedKey] = useState("");
    const location = useLocation();

    useEffect(()=>{
        const storedData = localStorage.getItem("userData");
        const parsedData = storedData ? JSON.parse(storedData) : null;
        setUserData(parsedData)
        
    },[])

    useEffect(() => {
        const menuItemIndex = menuItems.findIndex(item => item.key== location.pathname);
        setSelectedKey(String(menuItemIndex+1));         
    }, [location.pathname]); 
    
    const menuItems = [
        {
            key:"/employee/dashboard",
            icon: <FontAwesomeIcon icon={faChartLine} size="2x" color="#3f51b5" />,
            label: 'Dashboard',
            onClick: () => navigate('/employee/dashboard')
        },
        {
            key:"/employee/head-word",
            icon: <FontAwesomeIcon icon={faFileLines} size="2x" color="#3f51b5" />,
            label: 'Madde Başı',
            onClick: () => navigate('/employee/head-word')
        },
        {
            key: "/employee/my-information",
            icon:  <FontAwesomeIcon icon={faAddressCard} size="2x" color="#3f51b5" />,
            label: 'Bilgilerim',
            onClick: () => navigate('/employee/my-information')
        },
        {
            key:"go-to-home",
            icon: <FontAwesomeIcon icon={faLocationArrow} size="2x" color="#3f51b5" />,
            label: 'Sözlüğe Git',
            onClick: () => navigate('/')
        },
        {
            key:"logout",
            icon: <FontAwesomeIcon icon={faRightFromBracket} size="2x" color="#3f51b5" />,
            label: 'Çıkış Yap',
            onClick : ()=> _authService.logout()
                
            
        }
    ];
    const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <>
            <FontAwesomeIcon icon={faAddressCard} size="1x" color="#3f51b5" />
            <span className="ml-2">{userData.name} {userData.surname}</span>
            </>
          ),
        },
        {
            key: '2',
            label: (
                <>
                <FontAwesomeIcon icon={faSignature} size="1x" color="#3f51b5" />
                <span className="ml-2">{userData.username}</span>
                </>
            ),
        },
        {
          key: '3',
          label: (
            <>
            <FontAwesomeIcon icon={faShieldHalved} size="1x" color="#3f51b5" />
            <span className="ml-2"> {userData.role == "Employee" ? "Çalışan":"Yönetici"}</span>
            </>
          
          ),
        },
        {
          key: '4',
          label: (
            <>
            <FontAwesomeIcon icon={faCodeBranch} size="1x" color="#3f51b5" />
            <span className="ml-2"> {process.env.REACT_APP_VERSION}</span>
            </>
          
          ),
        },
        {
            key: '5',
            label: (
              <span className="w-full h-full"  onClick={(e)=>_authService.logout()}>
              <FontAwesomeIcon icon={faRightFromBracket} size="1x" color="#3f51b5" />
              <span className="ml-2" > Çıkış Yap</span>
              </span>
            
            ),
          }
    
      ];
    return(
        <>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="flex items-center justify-center gap-2   p-4" >
                        <img src="/files/images/turkces_logo.png" className="w-[30px]"  />
                        {
                            collapsed == false ? (
                                <span className="text-white font-bold text-[20px]">{process.env.REACT_APP_PROJECT_NAME}</span>
                            ):("")

                        }
                        
                    </div>
                    <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    items={menuItems.map((item, index) => ({
                        ...item,
                        key: `${index + 1}` 
                    }))}
                    />
                </Sider>

                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer }}>
                    <div className="flex justify-between">
                            <div className="flex items-center">

                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    }}
                                />
                                <strong>Çalışan Paneli</strong>
                            </div>

                            <div className="mr-10">
                                <Dropdown menu={{ items }} >
                                    
                                    <Avatar icon={<UserOutlined />} />
                                        
                                </Dropdown>
                            </div> 

                        </div>
                    </Header>
                    <Content
                    className="bg-[#F4F4F4] p-[24px]  min-h-svh rounded-xl m-[24px]"
                    
                    >

                        <main>
                            <Outlet /> {/* Alt bileşenler burada render edilecek */}
                        </main>
                        
                    </Content>

                </Layout>
            </Layout>
        </>
    );

}

export default EmployeeLayout;