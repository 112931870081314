import { Outlet } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";

const CustomerLayout = ()=>{
    return(
        <>
            <Navbar/>
            <main className="bg-white">
                <Outlet /> {/* Alt bileşenler burada render edilecek */}
            </main>
        </>
    );

}

export default CustomerLayout;