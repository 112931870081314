import { useEffect, useState } from 'react';
import './TematicList.css'
import TematicService from '../../../services/TematicService';
import { message } from 'antd';
import Loading from '../../../components/Loading';

const TematicList = () =>{

    const [dataSource, setDataSource] = useState([]);
    const _tematicService = TematicService();
    const [isLoadingTematicList, setIsLoadingTematicList]= useState(false)
    
    useEffect(()=>{

        getTematic();

    },[])

    const getTematic = async ()=>{
        try { 

            setIsLoadingTematicList(true);
            var response = await _tematicService.getTematicList();
            
            if (response.statusCode == 200 ) {
            
                console.log('response: ', response);
                
                setDataSource(response.data);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getTematic :", err);
        }finally{
            setIsLoadingTematicList(false);

        }
        
    }
    return (
        <>
            {
                    isLoadingTematicList == true ?
                    <Loading /> : 
                    <div id="tema-listesi-content">
                        <h2>Tema Listesi</h2>
                        <div className="tema-grid">

                            {
                                dataSource.map((item:any,index:any)=>(

                                    <a key={index} href="#" className="theme-card">
                                        {
                                            item.imgUrl == null || item.imgUrl =="" ?
                                            <img src={process.env.REACT_APP_FILE+"/uploads/no-image-found.png"} /> :
                                            <img src={process.env.REACT_APP_FILE+item.imgUrl} /> 
                                        }
                                        <div className="theme-title">{item.name}</div>
                                    </a>
                                ))
                            }

                        
                        </div>
                    </div>
            }
        </>
    );
}

export default TematicList;