import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { useState } from 'react';

const Navbar = () => {

    const navigate = useNavigate();

    const [word,setWord] = useState("");

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            navigate("/dictionary/tr/"+word)
        }
      };
    
    return (
        <>
        
            <div id="header">
                <div className="header-top">
                    <div className="header-container">

                    {/* Sol taraf - Logo ve başlık */}
                    <div className="left-section">
                        <a href="/" className="header-brand">
                        <span className="brand-logo">Türkçes</span>
                        <span className="brand-text">Türkçe Öğrenci Sözlüğü</span>
                        </a>
                    </div>

                    {/* Sağ taraf - Menü, sosyal medya ve giriş */}
                    <div className="right-section">
                        {/* Ana Menü */}
                        <nav className="nav-menu">
                        <a href="turkce-ogren.html" className="nav-link">
                            <i className="fas fa-graduation-cap" />
                            Öğren
                        </a>
                        <a href="/tematic-list" className="nav-link">
                            <i className="fas fa-palette" />
                            Temalar
                        </a>
                        <div className="nav-item">
                            <a href="#" className="nav-link">
                            <i className="fas fa-list" />
                            Listeler
                            <i className="fas fa-chevron-down" />
                            </a>
                        </div>
                        </nav>
                        {/* Giriş Butonu */}
                        <a href="/login" className="sign-up">Giriş Yap</a>
                    </div>
                    </div>
                </div>

                <div className="header-search">

                    <div className="search-container">
                        <div className="search-box">
                        {/* Dil Seçimi */}
                        <div id="header-language-select" className="lang-select">
                            <button className="lang-button">
                            <span className="header-selected-language">Türkçe</span>
                            <i className="fas fa-chevron-down" />
                            </button>
                            <div className="header-language-options">
                            <div className="header-language-option selected" data-value="turkce">Türkçe</div>
                            <div className="header-language-option" data-value="turkce-ingilizce">Türkçe-İngilizce</div>
                            <div className="header-language-option" data-value="turkce-rusca">Türkçe-Rusça</div>
                            <div className="header-language-option" data-value="turkce-hirvatca">Türkçe-Hırvatça</div>
                            <div className="header-language-option" data-value="turkce-arapca">Türkçe-Arapça</div>
                            </div>
                        </div>
                        <div className="vertical-divider" />
                        {/* Arama Input */}
                        <input type="text" id="header-search-input" value={word} 
                        onKeyDown={handleKeyDown}
                        onChange={e=>{setWord(e.target.value)}} placeholder="Ara..." autoComplete="off" spellCheck="false" />
                        {/* Aksiyon Butonları */}
                        <div className="search-actions">
                            <button id="header-toggle-keyboard" className="action-button">
                            <i className="fas fa-keyboard" />
                            </button>
                            <button id="header-search-button" 
                            onClick={()=>{

                                navigate("/dictionary/tr/"+word)
                                
                            }}
                            className="action-button">
                                <i className="fas fa-search" />
                            </button>
                        </div>
                        {/* Türkçe Karakterler Popup */}
                        <div id="header-turkce-karakterler-popup">
                            <div className="keyboard-buttons">
                            <button className="header-char-btn">ç</button>
                            <button className="header-char-btn">ğ</button>
                            <button className="header-char-btn">ı</button>
                            <button className="header-char-btn">ö</button>
                            <button className="header-char-btn">ş</button>
                            <button className="header-char-btn">ü</button>
                            </div>
                        </div>
                        </div>
                        {/* Öneriler */}
                        <div id="suggestions-container" className="header-hidden">
                        <ul id="suggestions-list" />
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default Navbar;




