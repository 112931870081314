import CustomerLayout from "../layouts/customerLayout/CustomerLayout";
import Dictionary from "../pages/customer/dictionary/Dictionary";
import Home from "../pages/customer/home/Home";
import TematicList from "../pages/customer/tematicList/TematicList";

const CustomerRoutes = [
    {
        path: '',
        element: <CustomerLayout />,
        children: [
            { path: '', element: <Home /> },
            { path: 'home', element: <Home /> },
            { path: 'tematic-list', element: <TematicList /> },
            { path: 'dictionary/:lang/:word', element: <Dictionary /> },
        ],
    },
];

export default CustomerRoutes;