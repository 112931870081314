import './Home.css'

const Home = ()=>{
    return(
        <>
          <main role="main" id="main-content">
            {/* Seçenekler Bölümü */}
            <section id="options-section">
              {/* Daha Fazla Örnek */}
              <a href="daha-fazla-ornek.html" className="option-box option-box-1">
                <div className="option-icon">
                  <i className="fas fa-plus-circle" />
                </div>
                <h3 className="option-title">Daha fazla örnek</h3>
                <p className="option-description">Örnek istediğiniz sözcükler için daha fazla örnek sunuyoruz.</p>
                <span className="card-arrow">
                  Keşfet <i className="fas fa-arrow-right" />
                </span>
                <div className="option-overlay" />
              </a>
              {/* Sözcük Listeleri */}
              <a href="sozcuk-listeleri.html" className="option-box option-box-2">
                <div className="option-icon">
                  <i className="fas fa-list-ul" />
                </div>
                <h3 className="option-title">Sözcük listeleri</h3>
                <p className="option-description">A1 ve A2 düzeyi sözcük listelerine buradan ulaşabilirsiniz.</p>
                <span className="card-arrow">
                  İncele <i className="fas fa-arrow-right" />
                </span>
                <div className="option-overlay" />
              </a>
              {/* Tema Listeleri */}
              <a href="/tematic-list" className="option-box option-box-3">
                <div className="option-icon">
                  <i className="fas fa-th-large" />
                </div>
                <h3 className="option-title">Tema listeleri</h3>
                <p className="option-description">Farklı temalardaki sözcük listelerine göz atın.</p>
                <span className="card-arrow">
                  Gözat <i className="fas fa-arrow-right" />
                </span>
                <div className="option-overlay" />
              </a>
              {/* Görseller */}
              <a href="gorseller.html" className="option-box option-box-4">
                <div className="option-icon">
                  <i className="fas fa-images" />
                </div>
                <h3 className="option-title">Görseller</h3>
                <p className="option-description">Görseller ile sözcüklerin anlamlarını kolayca kavrayın.</p>
                <span className="card-arrow">
                  Görüntüle <i className="fas fa-arrow-right" />
                </span>
                <div className="option-overlay" />
              </a>
            </section>
           
          </main>


        </>
    );

}

export default Home;
