import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faCheck, faCircleCheck, faCircleXmark, faEdit, faFontAwesome, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip, Popconfirm, message} from "antd";
import HeadWordService from "../../../../services/HeadWordService";
import { useNavigate } from "react-router-dom";
import MeaningService from "../../../../services/MeaningService";
import { useEffect, useState } from "react";



const MeaningTable = (props:any) =>{
    
    const _headWordService = HeadWordService();
    const _meaningService = MeaningService();
    const navigate = useNavigate();
    const [ userData, setUserData ] = useState(Object);
    
    useEffect(()=>{
        const storedData = localStorage.getItem("userData");
        const parsedData = storedData ? JSON.parse(storedData) : null;
        setUserData(parsedData)
    },[])
    

    const deleteMeaning= async(meaningId:string)=>{

        try {

            var data={
                meaningId:meaningId
            }

            var response = await _meaningService.deleteMeaning(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Anlam silindi")

                props.onMeaningDeleted();
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("delete meaning",err)
        }
    }

    const updatePublishedMeaning= async(meaningId:string,value:boolean)=>{

        try {

            var data={
                meaningId: meaningId ,
                isPublished:value
            }

            var response = await _meaningService.updatePublishedMeaning(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                if (value == true) {
                    message.success("Yayınlandı !")
                }
                else{
                    message.success("Yayından kaldırıldı !")

                }

                props.onUpdatePublishedMeaning();
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("delete meaning",err)
        }
    }


    const columns = [
        {
            title: '#',
            width: 150,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        {
            title: 'Madde Başı',
            dataIndex: 'name',
        },
        {
            title: 'Eş Sesli Kodu',
            dataIndex: 'homophoneCode',
        },
        {
            title: 'Anlam Sırası',
            dataIndex: 'order',
        },
        {
            title: 'Sözcüğün Anlam',
            dataIndex: 'definition',
            render: (htmlContent:string) => (
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            ),
        },
        {
            title: 'Sözcük Türü',
            dataIndex: 'wordType',
        },
        {
            title: 'Dil Seviyesi',
            dataIndex: 'languageLevel',
        },
        {
            title: '',
            width: 220,
            dataIndex: 'action',
            render: (action: { id:string; isPublished:boolean; }) => (
                <>

                    {
                        action.isPublished ==false ? 
                        <Popconfirm
                            title="Yayına almak istediğinizden emin misiniz?"
                            okText="Evet"
                            onConfirm={e=>updatePublishedMeaning(action.id,true)}
                            cancelText="İptal"
                        >
                            <Tooltip title="Yayınla">
                                <Button type="primary" className="bg-blue-500 ml-3 hover:!bg-blue-400">
                                    <FontAwesomeIcon icon={faCheck} size="1x"  />
                                </Button>
                            </Tooltip>
                        </Popconfirm> 
                        :
                        <Popconfirm
                        title="Yayından kaldırmak istediğinizden emin misiniz?"
                        onConfirm={e=>updatePublishedMeaning(action.id,false)}
                        okText="Evet"
                        cancelText="İptal"
                    >
                        <Tooltip title="Yayından Kaldır">
                            <Button type="primary" className="bg-purple-600 ml-3 hover:!bg-purple-400">
                                <FontAwesomeIcon icon={faXmark} size="1x"  />
                            </Button>
                        </Tooltip>
                    </Popconfirm> 
                    }

                    

                    <Tooltip title="Güncelle">
                        <Button type="primary" 
                        onClick={(e) => {
                            if (userData.role === "Manager") {
                                navigate(`/manager/update-meaning/${props.headWordId}/${action.id}`)                          
                            } else if (userData.role === "Employee") {
                                navigate(`/employee/update-meaning/${props.headWordId}/${action.id}`)                          
                            }
                        }}
                        className="bg-yellow-500 hover:!bg-yellow-400 ml-3">
                            <FontAwesomeIcon icon={faEdit} size="1x"  />
                        </Button>
                    </Tooltip>

                    
                    
                    <Popconfirm
                        title="Anlamı silmek istiyor musunuz?"
                        okText="Sil"
                        onConfirm={e=>deleteMeaning(action.id)}
                        cancelText="İptal"
                    >
                        <Tooltip title="Sil">
                            <Button type="primary" className="bg-red-500 ml-3 hover:!bg-red-400">
                                <FontAwesomeIcon icon={faTrashAlt} size="1x"  />
                            </Button>
                        </Tooltip>
                        
                    </Popconfirm>
                    
                    

                   
                </>
                    
            ),
        },

        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} locale={{ emptyText: <span className="text-red-400 font-bold">Anlam bulunamadı !</span>}}  />
        </>
    );

}

export default MeaningTable;