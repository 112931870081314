import axios from '../interceptors/AuthInterceptor';
import { IAddSubTematic } from '../models/tematic/IAddSubTematic';
import { IAddTematic } from '../models/tematic/IAddTematic';
import { ITematicIdRequest } from '../models/tematic/ITematicIdRequest';
import { IUpdateTematic } from '../models/tematic/IUpdateTematic';
import { IAddUser } from '../models/user/IAddUser';

const TematicService =()=> {

    const apiUrl = process.env.REACT_APP_API_URL


    const getTematic = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/Tematic/get-tematic`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const addTematic = async (data:IAddTematic) => {  
        try { 
            

            const formData = new FormData(); 

            formData.append("Name", data.name);
            formData.append("OrderCode", data.orderCode);
            formData.append("TematicImg", data.tematicImg);
            console.log('formData: ', formData);
            
            const response = await axios.post(
                `${apiUrl}/Tematic/add-tematic`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            
            ); 
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const updateTematic = async (data:IUpdateTematic) => {  
        try { 
            

            const formData = new FormData(); 

            formData.append("TematicId", data.tematicId);
            formData.append("Name", data.name);
            formData.append("OrderCode", data.orderCode);
            formData.append("TematicImg", data.tematicImg);
            console.log('formData: ', formData);
            
            const response = await axios.post(
                `${apiUrl}/Tematic/update-tematic`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            
            ); 
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const addSubTematic = async (data:IAddSubTematic) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/add-sub-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getByIdTematic = async (data:ITematicIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/get-by-id-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getParentTematic = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/Tematic/get-parent-tematic`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const getTematicList = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/Tematic/get-tematic-list`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const getSubTematic = async (data:any) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/get-sub-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const deleteTematic = async (data:ITematicIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/delete-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };


    return {
        getTematic,
        getParentTematic,
        addTematic,
        addSubTematic,
        getSubTematic,
        deleteTematic,
        getByIdTematic,
        updateTematic,
        getTematicList,
    };


}

export default TematicService;
