import { useState } from "react";

interface ImageData {
  imgUrl: string;
  description: string;
}

const ImageGallery = ({ images }: { images: ImageData[] }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleImageClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleClose = () => {
    setActiveIndex(null);
  };

  const handleNext = () => {
    if (activeIndex !== null && activeIndex < images.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePrev = () => {
    if (activeIndex !== null && activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

 

  return (
    <div className="anlam-gorsel">
      {/* Küçük Resimler */}
      <div className="gorsel-container">
        {images.map((image, i) => (
            <>
            <img
                key={i}
                src={`${process.env.REACT_APP_FILE}${image.imgUrl}`}
                className={`gorsel ${i === 0 ? "active" : ""}`}
                data-aciklama={image.description}
                onClick={() => handleImageClick(i)}
                />

            <button className="enlarge-button" onClick={() => handleImageClick(i)} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-width="2" d="M4 4l5 5m-5-5v4m0-4h4m12 0l-5 5m5-5v4m0-4h-4m-12 12l5-5m-5 5v-4m0 4h4m12 0l-5-5m5 5v-4m0 4h-4"/>
                </svg>
            </button>
            </>
        ))}
      </div>

      {/* Büyük Resim Modal */}
      {activeIndex !== null && (
        <div className="gorsel-container enlarged">
          <img
            src={`${process.env.REACT_APP_FILE}${images[activeIndex].imgUrl}`}
            className="gorsel active"
            data-aciklama={images[activeIndex].description}
          />
          <button className="enlarge-button" onClick={handleClose}>
            ✖
          </button>
          {activeIndex > 0 && (
            <div className="nav-arrow left-arrow" onClick={handlePrev}>
              ❮
            </div>
          )}
          {activeIndex < images.length - 1 && (
            <div className="nav-arrow right-arrow" onClick={handleNext}>
              ❯
            </div>
          )}
          <div className="gorsel-aciklama-enlarged">
            {images[activeIndex].description}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
