import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import HeadWordService from "../../../services/HeadWordService";
import { message } from "antd";
import Loading from "../../../components/Loading";
import './Dictionary.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "./ImageGallery";

const Dictionary = () => {
    const { word, lang } = useParams();

    const [dataSource, setDataSource] = useState<[]>([]);
    const _headWordService = HeadWordService();
    const [isLoadingHeadWordList, setIsLoadingHeadWordList]= useState(false)

    const audioRef = useRef<HTMLAudioElement | null>(null);

    
    useEffect(()=>{
        searchWord()
        
    },[word])


    const playSound = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const cleanText = (html: string) => {
        return html
          .replace(/<\/?[^>]+(>|$)/g, "")  // HTML etiketlerini temizle
          .replace(/&nbsp;/g, " ")         // &nbsp; yerine normal boşluk koy
          .replace(/\s+/g, " ")            // Fazla boşlukları tek boşluğa düşür
          .trim();                          // Baştaki ve sondaki boşlukları kaldır
    };

    const searchWord = async ()=>{
        try { 

            setIsLoadingHeadWordList(true);
            var data ={
                word: word!,
                language: lang!
            }
            var response = await _headWordService.searchWord(data);
            
            if (response.statusCode == 200 ) {
            
                console.log('response: ', response);
                
                setDataSource(response.data);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("SearchWord :", err);
        }finally{
            setIsLoadingHeadWordList(false);

        }
        
    }

    const getLevelColor = (level: string) => {
        switch (level) {
            case "A1": return "bg-[#003366]";
            case "A2": return "bg-[#2e8b57]";
            case "B1": return "bg-yellow-500";
            case "B2": return "bg-orange-500";
            case "C1": return "bg-red-500";
            case "C2": return "bg-purple-500";
            default: return "bg-gray-500";
        }
    };

    return (
        <>
            {
                isLoadingHeadWordList == true ?
                <Loading /> : 
                <div id="result-wrapper" className="pt-[50px]" >

                    {
                        dataSource.map((item:any, index:number)=>(

                            <div key={index} id="search-results" className="madde-basi-content" >
                                <div className="madde-basi">
                                    <h2 className="common-text-style font-bold">{item.name}  ({item.homophoneCode})</h2>
                                    <div className="sozcuk-turu">erererefd dfdf dfd </div>
                                    {
                                        item.soundUrl == null || item.soundUrl == "" ? <></> :
                                        <button className="play-button" onClick={playSound} >
                                            <FontAwesomeIcon icon={faVolumeHigh} size="2x" color="#E16251" />
                                            <audio ref={audioRef} src={process.env.REACT_APP_FILE+item.soundUrl} />
                                        </button>
                                    }
                                </div>

                                {
                                    item.meanings.map((itemMeaning:any,itemMeaningIndex:number)=>(
                                        <div key={itemMeaningIndex} className="tematik-secimler">
                                            <a href="${hedefSayfa}" className="tematik-secim-link">{itemMeaning.wordType}</a>               
                                        </div>
                                    ))
                                }

                                <div className="sozcuk-bicimi">
                                    <button className="toggle-button"  aria-expanded="false"></button>
                                    <div className="sozcuk-bicimi-content">
                                        <p className="common-text-style"
                                            dangerouslySetInnerHTML={{ __html: item.dictionaryFormat }}
                                        ></p>
                                    </div>
                                </div>

                                <div className="separator-line">
                                    <button className="add-to-list-button">
                                        <span className="list-icon">
                                        {/* Liste ikonunu temsil eden SVG */}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                                            {/* Artı İşareti ile Liste İkonu */}
                                            <rect x={8} y={16} width={36} height={4} />
                                            <rect x={8} y={30} width={36} height={4} />
                                            <rect x={8} y={44} width={36} height={4} />
                                            <rect x={48} y={24} width={4} height={24} />
                                            <rect x={40} y={36} width={24} height={4} />
                                        </svg>
                                        </span>
                                        <span className="add-to-list-text">Listeme ekle</span>
                                    </button>
                                </div>

                                {
                                    item.meanings.map((meaning:any,meaningIndex:number)=>(

                                        <span key={meaningIndex}>

                                            <a href={`sozcuk-listeleri.html?seviye=${meaning.languageLevel}`}
                                            className={`dil-seviyesi p-3 ${getLevelColor(meaning.languageLevel)}`}>
                                                {meaning.languageLevel}
                                            </a>

                                            <div className="sozcugun-anlami">
                                                <p dangerouslySetInnerHTML={{ __html: meaning.definition }}></p>
                                            </div>

                                            <div className="anlam-bloku ">
                                                <div className="ornek-cumle " >
                                                    <ul>
                                                        {
                                                            meaning.exampleSentences.map((exampleSentence:any,exampleSentenceIndex:number)=>(
                                                                <li key={exampleSentenceIndex} dangerouslySetInnerHTML={{ __html: exampleSentence }}></li>
                                                            ))
                                                        }
                                                    </ul>
                                                
                                                        
                                                    <button className="daha-fazla-button" >Daha Fazla </button>
                                                
                                                </div>

                                                <>
                                                    <ImageGallery images={meaning.meaningImages} />
                                                </>

{/* 
                                                {
                                                    meaning.meaningImages.length > 0 ? 
                                                    <div className="anlam-gorsel">
                                                        <div className="gorsel-container">
                                                            
                                                                <img src={process.env.REACT_APP_FILE+meaning.meaningImages[0].imgUrl} className="gorsel${i === 0 ? ' active' : ''}" data-aciklama="${cleanDescription}" />
                                                                    
                                                            <button className="enlarge-button" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                    <path fill="none" stroke="currentColor" stroke-width="2" d="M4 4l5 5m-5-5v4m0-4h4m12 0l-5 5m5-5v4m0-4h-4m-12 12l5-5m-5 5v-4m0 4h4m12 0l-5-5m5 5v-4m0 4h-4"/>
                                                                </svg>
                                                            </button>
                                                        </div>

                                                        <div className="gorsel-container enlarged">
                                                            <img src="/files/images/turkces_logo.png" alt="Görsel 2" className="gorsel active" data-aciklama="ölölöl" />
                                                            <button className="enlarge-button">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                    <path fill="none" stroke="currentColor" stroke-width="2" d="M4 4l5 5m-5-5v4m0-4h4m12 0l-5 5m5-5v4m0-4h-4m-12 12l5-5m-5 5v-4m0 4h4m12 0l-5-5m5 5v-4m0 4h-4"></path>
                                                                </svg>
                                                            </button>
                                                            <div className="nav-arrow left-arrow">❮</div>
                                                            <div className="nav-arrow right-arrow">❯</div>
                                                            <div className="gorsel-aciklama-enlarged">llölö</div>
                                                        </div>

                                                    </div> : <></>
                                                } */}


                                            </div>
                                        </span>

                                    ))
                                    

                                }


                            </div>

                        ))
                    }
                    
                    <div id="soz-ailesi-ana-container" className="madde-basi-content">
                        
                        {/* Söz Ailesi Bölümü */}
                        {
                            dataSource.map((item:any, index:number)=>(
                        
                                <div key={index} className="soz-ailesi">
                                    <h3 className="font-bold">{item.name} ({item.homophoneCode}) - Söz Ailesi</h3>
                                    {
                                        item.wordFamilies.map((i:any,index2:number)=>(
                                            <ul key={index2} >   
                                                <li>{cleanText(i)}</li>
                                            </ul>
                                        ))
                                    }
                                    <button className="daha-fazla-button" >Daha Fazla</button>
                                </div>

                            ))
                        }

                        {/* Türkçe Öğren Bölümü */}
                        <div className="turkce-ogren">
                            <a href="turkce-ogren.html" className="turkce-ogren-baslik">
                                <h3>Türkçe Öğren</h3>
                            </a>

                            <div className="turkce-ogren-content">
                                <a href="turkce-ogren.html" className="turkce-ogren-link">
                                <img src="/files/images/turkce-ogren/student.jpg" alt="Türkçe Öğren" className="turkce-ogren-image" />
                                <div className="skills-grid">
                                    <div className="skill-item">
                                    <div className="skill-circle">
                                        <img src="/files/images/turkce-ogren/oku.png" alt="Oku" className="skill-icon" />
                                    </div>
                                    <span className="skill-name">Oku</span>
                                    </div>
                                    <div className="skill-item">
                                    <div className="skill-circle">
                                        <img src="/files/images/turkce-ogren/yaz.png" alt="Yaz" className="skill-icon" />
                                    </div>
                                    <span className="skill-name">Yaz</span>
                                    </div>
                                    <div className="skill-item">
                                    <div className="skill-circle">
                                        <img src="/files/images/turkce-ogren/dinle.png" alt="Dinle" className="skill-icon" />
                                    </div>
                                    <span className="skill-name">Dinle</span>
                                    </div>
                                    <div className="skill-item">
                                    <div className="skill-circle">
                                        <img src="/files/images/turkce-ogren/konus.png" alt="Konuş" className="skill-icon" />
                                    </div>
                                    <span className="skill-name">Konuş</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>

                        {/* Tema Listesi Bölümü */}
                        <div className="tema-listesi">

                            <a href="tema.html" className="tema-listesi-baslik">
                                <h3>Tema Listesi</h3>
                            </a>

                            <div className="skills-grid">
                                <a href="saglik.html" className="skill-item tema-card">
                                <div className="skill-circle">
                                    <img src="/files/images/tema_listesi/saglik.jpg" alt="Sağlık" className="skill-icon" />
                                </div>
                                <span className="skill-name">Sağlık</span>
                                </a>
                                <a href="alisveris.html" className="skill-item tema-card">
                                <div className="skill-circle">
                                    <img src="/files/images/tema_listesi/alisveris.jpg" alt="Alışveriş" className="skill-icon" />
                                </div>
                                <span className="skill-name">Alışveriş</span>
                                </a>
                                <a href="hayvanlar.html" className="skill-item tema-card">
                                <div className="skill-circle">
                                    <img src="/files/images/tema_listesi/hayvanlar.jpg" alt="Hayvanlar" className="skill-icon" />
                                </div>
                                <span className="skill-name">Hayvanlar</span>
                                </a>
                                <a href="yiyecek-ve-icecekler.html" className="skill-item tema-card">
                                <div className="skill-circle">
                                    <img src="/files/images/tema_listesi/yiyecek-ve-icecekler.jpg" alt="Yiyecek ve İçecekler" className="skill-icon" />
                                </div>
                                <span className="skill-name">Yiyecek ve İçecekler</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Dictionary;